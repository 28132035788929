import { Controller } from "@hotwired/stimulus"
import Chartkick from "chartkick"
import { de, enGB, pt } from "date-fns/locale"

// Connects to data-controller="elevator-chart"
export default class extends Controller {
  static targets = ["chartContainer"]

  static values = { locale: String }

  connect() {
    setTimeout(() => {
      this.setOptions()
    }, 100)
  }

  getLocale() {
    switch (this.localeValue) {
      case "de":
        return de
      case "en":
        return enGB
      case "pt":
        return pt
      default:
        return enGB
    }
  }

  setOptions() {
    const chart = Chartkick.charts[this.chartContainerTarget.id]
    const existingOptions = chart.getOptions()

    chart.setOptions({
      library: {
        ...(existingOptions.library || {}),
        scales: {
          x: {
            type: "time",
            adapters: {
              date: {
                locale: this.getLocale(),
              },
            },
          },
        },
      },
    })
  }
}
