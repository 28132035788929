import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { src: String, duration: Number };

  connect() {
    console.log('Refresh controller connected')
    this.interval = setInterval(() => {
      this.element.setAttribute("src", this.srcValue);
    }, this.durationValue);
  }

  disconnect() {
    clearInterval(this.interval);
  }
}
